import _RequestClient from "./RequestClient";
import _Provider from "./Provider";
import _SearchProvider from "./SearchProvider";
import _Socket from "./Socket";
var exports = {};

/**
 * Manages communications with Companion
 */
const RequestClient = _RequestClient;
const Provider = _Provider;
const SearchProvider = _SearchProvider;
const Socket = _Socket;
exports = {
  RequestClient,
  Provider,
  SearchProvider,
  Socket
};
export default exports;
const _RequestClient2 = exports.RequestClient,
      _Provider2 = exports.Provider,
      _SearchProvider2 = exports.SearchProvider,
      _Socket2 = exports.Socket;
export { _RequestClient2 as RequestClient, _Provider2 as Provider, _SearchProvider2 as SearchProvider, _Socket2 as Socket };