var exports = {};

/**
 * This module serves as an Async wrapper for LocalStorage
 */
exports.setItem = (key, value) => {
  return new Promise(resolve => {
    localStorage.setItem(key, value);
    resolve();
  });
};

exports.getItem = key => {
  return Promise.resolve(localStorage.getItem(key));
};

exports.removeItem = key => {
  return new Promise(resolve => {
    localStorage.removeItem(key);
    resolve();
  });
};

export default exports;